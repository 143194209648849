import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const FeatureBanner = makeShortcode("FeatureBanner");
const NavigationTiles = makeShortcode("NavigationTiles");
const NavigationTile = makeShortcode("NavigationTile");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <FeatureBanner mdxType="FeatureBanner" />
    <NavigationTiles mdxType="NavigationTiles">
      <NavigationTile title="Naturalis" linkPage="/portfolio/naturalis" imagePath="home/home-naturalis.jpg" imageAlt="Naturalis" mdxType="NavigationTile" />
      <NavigationTile title="Prop Box" linkPage="/portfolio/prop-box" imagePath="home/home-prop-box.jpg" imageAlt="Prop Box" mdxType="NavigationTile" />
      <NavigationTile title="Novels Reimagined" linkPage="/portfolio/novels-reimagined" imagePath="portfolio/novels-reimagined/novels-reimagined-thumb.jpg" imageAlt="Novels Reimagined" mdxType="NavigationTile" />
    </NavigationTiles>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      